import React from 'react';
import './Weather.css';

const Card = ({day}) => {
    const imgURL = "owf owf-"+ day.weather[0].id +" owf-5x icon-style"

    console.log(imgURL)
    return (
        <div className="Temperature">
          <i className={imgURL}></i>

          <h2>{Math.round(day.main.temp-273)} °C</h2>
        </div>)
}

export default Card
