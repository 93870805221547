import "./Book.css";
import { HashLink as Link } from 'react-router-hash-link';
import ImgForBook from "../../image/KeyForBook.png";

const Book = (props) => {

  return (
    
    <div className="KeyForBook">
    <Link to="/booking#jakorPages"><img src={ImgForBook}></img></Link>
    </div>
  );
};

export default Book;
