import HeadForAllPage from "../HeadForAllPage/HeadForAllPage";
import "./Living.css";
import J34 from "../image/gallery/34.jpg";
import J40 from "../image/gallery/40.jpg";
import J48 from "../image/gallery/48.jpg";
import cot1 from "../image/gallery/2.jpg";
import cot2 from "../image/nomers/cot2.jpg";
import cot3 from "../image/nomers/cot3.jpg";
import cot4 from "../image/nomers/cot4.jpg";
import cot5 from "../image/nomers/cot5.jpg";
import { useState } from "react";
import Lab from "../image/ImgCards/Lab.png";
import Area from "../image/ImgCards/Area.png";
import Person from "../image/ImgCards/Person.png";
import Bed from "../image/ImgCards/Bed1.png";
import cot6 from "../image/nomers/two6.jpg";
import Toilet from "../image/ImgCards/Toilet.png";
import Kitchen from "../image/ImgCards/Kitchen.png";

import React from "react";
import { FormattedMessage } from "react-intl";
import { HashLink as Link } from "react-router-hash-link";

import SliderCotage from "./SliderCotage";

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow, Pagination } from "swiper/core";

SwiperCore.use([EffectCoverflow, Pagination]);

const Living = (props) => {
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [toggleState, setToggleState] = useState(1);

  return (
    <div id="jakorPages">
      <HeadForAllPage></HeadForAllPage>

      <div className="tabs_block">
        <div className="blockWraper">
          <div
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            <FormattedMessage id="Numbers" />
          </div>
          <div className="Probel"></div>
          <div
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            <FormattedMessage id="Cotteges" />
          </div>
        </div>
      </div>

      <div className="content_tabs">
        <div
          className={toggleState === 2 ? "content active-content" : "content"}
          onClick={() => toggleTab(2)}
        >
          <div className="AllCards">
            <div className="CardsWrapper">
              <div className="Card">
                <img className="ImagesLiving" src={J34} alt="image living"></img>
                <div className="CardBattons">
                  <Link
                    to="/luxe#jakorPages"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div className="BookCard">
                      <FormattedMessage id="Book" />
                    </div>
                  </Link>
                </div>
                <div className="NameCard">
                  <FormattedMessage id="Luxe" />
                </div>
                <span>
                  <FormattedMessage id="Price1" />
                </span>
                <div className="CardLux IconWrapperBig">
                  <div className="IconLine IconLineLux"></div>
                  <div className="IconWrapperBig1">
                    <div className="IconIcons">
                      <img src={Person}></img>
                      <div className="IconText">
                        5 <FormattedMessage id="Guess" />
                      </div>
                    </div>
                    <div className="IconIcons">
                      <img src={Lab}></img>
                      <div className="IconText">
                        1 <FormattedMessage id="Sleepq" />
                      </div>
                    </div>
                    <div className="IconIcons">
                      <img src={Bed}></img>
                      <div className="IconText">
                        1 <FormattedMessage id="Sleep" />
                      </div>
                    </div>
                  </div>
                  <div className="Fix">
                    <div className="IconIcons">
                      <img src={Area}></img>
                      <div className="IconText">
                        35 м<sup>2</sup>
                      </div>
                    </div>
                    <div className="IconIcons">
                      <img src={Toilet}></img>
                      <div className="IconText">1 <FormattedMessage id="Toilet" /></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Card">
                <img className="ImagesLiving" src={J40} alt="blin"></img>
                <div className="CardBattons">
                  <Link
                    to="/twoPlacesWithMans#jakorPages"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div className="BookCard">
                      <FormattedMessage id="Book" />
                    </div>
                  </Link>
                </div>
                <div className="NameCard">
                  <FormattedMessage id="WithMan" />{" "}
                </div>
                <span>
                  <FormattedMessage id="Price2" />
                </span>
                <div className="CardIconWrapper">
                  <div className="IconLine"></div>
                  <div className="Wrap">
                    <img src={Person}></img>
                    <div className="IconText">
                      2 <FormattedMessage id="Guess" />
                    </div>
                    <img src={Lab}></img>
                    <div className="IconText">
                      1 <FormattedMessage id="Sleep" />
                    </div>
                    <img src={Area}></img>
                    <div className="IconText">
                      12 м<sup>2</sup>
                    </div>
                  </div>
                </div>
                <div className="Fix Fixer">
                  <div className="IconIcons">
                    <img src={Toilet}></img>
                    <div className="IconText">1 <FormattedMessage id="Toilet" /></div>
                  </div>
                </div>
              </div>
              <div className="Card">
                <img className="ImagesLiving" src={cot6} alt="blin"></img>
                <div className="CardBattons">
                  <Link
                    to="/threePlacesWithBalcon#jakorPages"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div className="BookCard">
                      <FormattedMessage id="Book" />
                    </div>
                  </Link>
                </div>
                <div className="NameCard">
                  <FormattedMessage id="ThreePlace" />{" "}
                </div>
                <span>
                  <FormattedMessage id="Price3" />
                </span>
                <div className="CardIconWrapper">
                  <div className="IconLine"></div>
                  <div className="Wrap">
                    <img src={Person}></img>
                    <div className="IconText">
                      3 <FormattedMessage id="Guess" />
                    </div>
                    <img src={Lab}></img>
                    <div className="IconText">
                      1 <FormattedMessage id="Sleep" />
                    </div>
                    <img src={Area}></img>
                    <div className="IconText">
                      16 м<sup>2</sup>
                    </div>
                  </div>
                </div>
                <div className="Fix Fixer">
                  <div className="IconIcons">
                    <img src={Toilet}></img>
                    <div className="IconText">1 <FormattedMessage id="Toelet" /></div>
                  </div>
                </div>
              </div>
              <div className="Card">
                <img className="ImagesLiving" src={J48} alt="blin"></img>
                <div className="CardBattons">
                  <Link
                    to="/twoPlacesWithBalcon#jakorPages"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div className="BookCard">
                      <FormattedMessage id="Book" />
                    </div>
                  </Link>
                </div>
                <div className="NameCard">
                  <FormattedMessage id="TwoPlace" />{" "}
                </div>
                <span>
                  <FormattedMessage id="Price4" />
                </span>

                <div className="CardIconWrapper">
                  <div className="IconLine"></div>
                  <div className="Wrap">
                    <img src={Person}></img>
                    <div className="IconText">
                      2 <FormattedMessage id="Guess" />
                    </div>
                    <img src={Lab}></img>
                    <div className="IconText">
                      1 <FormattedMessage id="Sleep" />
                    </div>
                    <img src={Area}></img>
                    <div className="IconText">
                      16 м<sup>2</sup>
                    </div>
                  </div>
                </div>
                <div className="Fix Fixer">
                  <div className="IconIcons">
                    <img src={Toilet}></img>
                    <div className="IconText">1 <FormattedMessage id="Toelet" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={toggleState === 1 ? "content active-content" : "content"}
          onClick={() => toggleTab(1)}
        >

          <div className="AllCards">
            <div className="CardsWrapper">
              <SliderCotage name1={cot2} name2={cot1} name3={cot3} name4={cot4} name5={cot6} name6={cot5} />
              <div className="CardCotageMedia" style={{ marginTop: '0px', width: '549px', height: '180px' }}>
                <div className="CardBattons">
                  <Link
                    to="/cottege#jakorPages"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <div className="BookCard">
                      <FormattedMessage id="Book" />
                    </div>
                  </Link>
                </div>
                <div className="NameCard">
                  <FormattedMessage id="Cottege" />
                </div>
                <span>
                  <FormattedMessage id="Price5" />
                </span>

                <div className="CardLux CardCotage1">
                  <div className="IconWrapperBig1">
                    <div className="IconLine IconLineLux12"></div>
                    <div className="IconIcons">
                      <img src={Person}></img>
                      <div className="IconText">
                        7 <FormattedMessage id="Guess" />
                      </div>
                    </div>
                    <div className="IconIcons">
                      <img src={Lab}></img>
                      <div className="IconText">
                        1 <FormattedMessage id="Sleepq" />
                      </div>
                    </div>
                    <div className="IconIcons">
                      <img src={Bed}></img>
                      <div className="IconText">
                        2 <FormattedMessage id="Sleep2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Fixer">
                  <div className="IconIcons">
                    <img src={Area}></img>
                    <div className="IconText">
                      70 м<sup>2</sup>
                    </div>
                  </div>
                  <div className="IconIcons">
                    <img src={Kitchen}></img>
                    <div className="IconText">1 <FormattedMessage id="Kik" /></div>
                  </div>
                  <div className="IconIcons">
                    <img src={Toilet}></img>
                    <div className="IconText">3 <FormattedMessage id="Toelet1" /></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Living;
