import "./MainBakground.css";
import MainBackgroundIm from "../../image/header/main2.jpg";
import MainBackgroundIm2 from "../../image/header/main11.jpg";
import MainBackgroundIm3 from "../../image/header/main3.jpg";
import Logo from "../../image/header/logo.png";
import { Carousel } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import WeatherMain from "../Weather/WeatherMain";
import 'bootstrap/dist/css/bootstrap.min.css';


const MainBackground = () => {
  return (
    <div className="MainBackground">
      <div className="WeatherInBook2">
        <WeatherMain></WeatherMain>
      </div>
      <Carousel>

        <Carousel.Item>
          <img src={MainBackgroundIm} alt="Carpation" className="d-block w-100"></img>
          <Carousel.Caption><img src={Logo} alt="fu" style={{ 'margin-bottom': '5%', 'width': '30%' }}></img><div className="MainWordsInBack"><p className="firstEl"><FormattedMessage id='FirstSlide' /></p>
            <p className="SecondEl"><FormattedMessage id='FirstSlide2' /></p>
            <p className="ThreeEl"><FormattedMessage id='FirstSlide3' /></p></div> </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={MainBackgroundIm2} alt="Carpation" className="d-block w-100" ></img>
          <Carousel.Caption><img src={Logo} alt="fu" style={{ 'margin-bottom': '5%', 'width': '30%' }}></img><div className="MainWordsInBack"><p className="firstEl"><FormattedMessage id='SecondSlide' /></p>
            <p className="SecondEl"><FormattedMessage id='SecondSlide2' /></p>
            <p className="ThreeEl"><FormattedMessage id='SecondSlide3' /></p></div></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={MainBackgroundIm3} alt="Carpation" className="d-block w-100"></img>
          <Carousel.Caption><img src={Logo} alt="fu" style={{ 'margin-bottom': '5%', 'width': '30%' }}></img><div className="MainWordsInBack"><p className="firstEl"><FormattedMessage id='ThirdSlide' /></p>
            <p className="SecondEl"><FormattedMessage id='ThirdSlide2' /></p>
            <p className="ThreeEl"><FormattedMessage id='ThirdSlide3' /></p></div></Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default MainBackground;
