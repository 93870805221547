import "./UpperFooter.css";
import Icon, {
  HomeOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  FacebookOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import arrow from "../image/key.png";
import { HashLink as Link } from 'react-router-hash-link';
import PlanetHotel from "../image/end/PlanetHotels.png";


import { FormattedMessage } from "react-intl";
import BookingImg from "../image/book/booking.png";

const UpperFooter = () => {
  return (
    <nav className="AllInUpperFooter">
      <div className="UpperFooterWrapper">
        <div className="UpperFooterIcon">
          <div className="Centertext">
            <div className="FixKurw">
              <FormattedMessage id="MainContText2" />{" "}
            </div>
          </div>

          <div className="TextContaxtWrapper">
            <div className="TextContacts">
              <div className="UpperFooterContacts">
                <HomeOutlined style={{ fontSize: "22px" }} />
              </div>
              <div className="TextContacts">
                <FormattedMessage id="Place" />
              </div>
            </div>

            <div className="TextContacts">
              <div className="UpperFooterContacts">
                <PhoneOutlined style={{ fontSize: "22px" }} />
              </div>
              <div className="TextContacts">
                +3 (098) 111-20-20
                <br />
                +3 (098) 024-82-81
              </div>
            </div>

            <div className="TextContacts">
              <div className="UpperFooterContacts">
                <MailOutlined style={{ fontSize: "22px" }} />
              </div>
              <div className="TextContacts">alpiyskyi@gmail.com</div>
            </div>

            <div className="TextContacts">
              <div className="UpperFooterContacts">
                <GlobalOutlined style={{ fontSize: "22px" }} />
              </div>
              <div className="TextContacts">www.alphotel.com.ua</div>
            </div>
          </div>
        </div>

        <div className="UpperFooterIcon">
          <div className="Centertext">
            <div className="FixKurw">
              {" "}
              <FormattedMessage id="CottegesAndNum" />{" "}
            </div>
          </div>
          <div className="TextContaxtWrapper WrapperTextLiving">
            <div className="TextContacts">
              <img src={arrow}></img>
              <Link
                to="/cottege#jakorPages"
                style={{ textDecoration: "none", color: "black" }}
              >
                <FormattedMessage id="Cottege" />
              </Link>
            </div>
            <div className="TextContacts">
              <img src={arrow}></img>
              <Link
                to="/luxe#jakorPages"
                style={{ textDecoration: "none", color: "black" }}
              >
                <FormattedMessage id="Luxe" />
              </Link>
            </div>
            <div className="TextContacts">
              <img src={arrow}></img>
              <Link
                to="/threePlacesWithBalcon#jakorPages"
                style={{ textDecoration: "none", color: "black" }}
              >
                <FormattedMessage id="ThreePlace" />
              </Link>
            </div>
            <div className="TextContacts">
              <img src={arrow}></img>
              <Link
                to="/twoPlacesWithBalcon#jakorPages"
                style={{ textDecoration: "none", color: "black" }}
              >
                <FormattedMessage id="TwoPlace" />
              </Link>
            </div>
            <div className="TextContacts">
              <img src={arrow}></img>
              <Link
                to="/twoPlacesWithMans#jakorPages"
                style={{ textDecoration: "none", color: "black" }}
              >
                <FormattedMessage id="WithMan" />
              </Link>
            </div>
          </div>
        </div>

        <div className="UpperFooterIcon">
          <div className="Centertext">
            <div className="FixKurw">
              {" "}
              <FormattedMessage id="Recomend" />{" "}
            </div>
          </div>
          <div className="PlanetImg">
            <img className="BookingImg" src={BookingImg}></img>
            <img className="PlanetHotel" src={PlanetHotel}></img>
          </div>
          <div className="IconFooter">
            <div className="Icons">
              <a href="https://www.facebook.com/hotelalpiyskiy/">
                <FacebookOutlined
                  style={{
                    fontSize: "30px",
                    paddingTop: "15px",
                    paddingRight: "20px",
                    color: "#000000",
                  }}
                />
              </a>
            </div>

            <div className="Icons">
              <a href="https://instagram.com/alp_hotel?utm_medium=copy_link">
                <InstagramOutlined
                  style={{
                    fontSize: "30px",
                    paddingTop: "15px",
                    paddingRight: "20px",
                    color: "#000000",
                  }}
                />
              </a>
            </div>
          </div>{" "}
        </div>
      </div>
    </nav>
  );
};

export default UpperFooter;
