import Benefits from "./Benefits/Benefits"
import MainBackground from "./MainBackground/MainBackground";
import SliderMain from "./SliderInMain/SliderMain";
import AboutUs from "./AboutUs/AboutUs";
import Reviews from "./Reviews/Reviews"
import RecomendFor from "./RecomendFor/RecomendFor"

const Main = () => {
    return (
        <div id="jakorPages">
            <MainBackground />
            <AboutUs></AboutUs>
            <SliderMain />
            <Benefits />
            <Reviews></Reviews>
            <RecomendFor></RecomendFor>
        </div>
    )
}

export default Main;