import "./ThreePlacesWithBalcon.css";
import LivingSlider from "../../Living/LivingSlider/LivingSlider";
import HeadForAllPage from "../../HeadForAllPage/HeadForAllPage";
import Numbers from "../Numbers";
import { FormattedMessage } from "react-intl";
import threeplace3 from "../../image/nomers/threeplace3.jpg";
import threeplace4 from "../../image/nomers/threeplace4.jpg";
import threeplace5 from "../../image/nomers/threeplace5.jpg";
import threeplace6 from "../../image/nomers/threeplace6.jpg";
import J32 from "../../image/gallery/32.jpg";
import two6 from "../../image/nomers/two6.jpg";
import fen from "../../image/fen.png";
import wifi from "../../image/wifi.png";
import TV from "../../image/TV.png";
import hol from "../../image/hol.png";
import balcon from "../../image/balcon.png";
import toelet from "../../image/Toelet.png";

const ThreePlacesWithBalcon = () => {
  return (
    <div id="jakorPages">
      <HeadForAllPage></HeadForAllPage>
      <LivingSlider name1={threeplace3} name2={two6} name3={threeplace4} name4={threeplace5} name5={threeplace6} name6={J32}/>

      <div className="LuxeBenefitsWrapper">
        <div className="BenefitsWraper">
          <div className="BenefitsWraperIcon">
            <div className="BenefitsTitle1"><FormattedMessage id='Amenities' /></div>
            <div className="BenefitsLine"></div>
            <div className="BenefitsIconWrapper">
              <div className="BenefitsIcon">
                <img className="Tick" src={wifi}></img>
                Wifi
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={fen}></img><FormattedMessage id='Fen' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={TV}></img><FormattedMessage id='Tele' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={hol}></img><FormattedMessage id='Holod' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={balcon}></img><FormattedMessage id='Balcon' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={toelet}></img><FormattedMessage id='Toelet' />
              </div>
            </div>
          </div>
          <div className="BookingCardWrapper">
            <div className="BookingTitle3WithBalcon"><FormattedMessage id='ThreePlace' /></div>
            <div className="BookingLine"></div>
            <div className="BookingPrice"><FormattedMessage id='Price3' /></div>

            <div className="BookingCard">
              <div className="allInBookLiving">


                  <iframe src="Book1.html" scrolling="no"></iframe>


              </div>
            </div>


          </div>
        </div>
      </div>

      <Numbers name={<FormattedMessage id='Opis4' />} />
    </div>
  );
};

export default ThreePlacesWithBalcon;
