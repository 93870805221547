import "./Numbers.css";
const Numbers = (props) => {
  return (
    <div>
      <div className="TextWrapper">
        <div className="LineText"></div>
        <div className="Text">
          {props.name}
        </div>
      </div>
    </div>
  );
};

export default Numbers;
