import LivingSlider from "../../Living/LivingSlider/LivingSlider";
import HeadForAllPage from "../../HeadForAllPage/HeadForAllPage";
import J40 from "../../image/gallery/40.jpg";
import man2 from "../../image/nomers/man2.jpg";
import man3 from "../../image/nomers/man3.jpg";
import fen from "../../image/fen.png";
import wifi from "../../image/wifi.png";
import TV from "../../image/TV.png";
import hol from "../../image/hol.png";
import Numbers from "../Numbers.js";
import One from "../../image/gallery/1.jpg";
import Two from "../../image/gallery/2.jpg";
import Three from "../../image/gallery/3.jpg";

import { FormattedMessage } from "react-intl";
import toelet from "../../image/Toelet.png";

const TwoPlacesWithMans = () => {
  return (
    <div id="jakorPages">
      <HeadForAllPage></HeadForAllPage>
      <LivingSlider name1={J40} name2={man2} name3={man3} name4={One} name5={Two} name6={Three} />
      <div className="LuxeBenefitsWrapper">
        <div className="BenefitsWraper">
          <div className="BenefitsWraperIcon">
            <div className="BenefitsTitle1"><FormattedMessage id='Amenities' /></div>
            <div className="BenefitsLine"></div>
            <div className="BenefitsIconWrapper">
              <div className="BenefitsIcon">
                <img className="Tick" src={wifi}></img>
                Wifi
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={fen}></img><FormattedMessage id='Fen' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={TV}></img><FormattedMessage id='Tele' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={hol}></img><FormattedMessage id='Holod' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={toelet}></img><FormattedMessage id='Toelet' />
              </div>
            </div>
          </div>
          <div className="BookingCardWrapper">
            <div className="BookingTitleMans"><FormattedMessage id='WithMan' /></div>
            <div className="BookingLine"></div>
            <div className="BookingPrice"><FormattedMessage id='Price2' /></div>
            <div className="BookingCard">
              <div className="allInBookLiving">


                <iframe src="Book1.html" scrolling="no"></iframe>


              </div>
            </div>
          </div>
        </div>
        <Numbers name={<FormattedMessage id='Opis3' />} />
      </div>
    </div>
  );
};

export default TwoPlacesWithMans;
