import React, { Component } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import './MainHam.css'

class MenuContent extends Component {

    render() {
        return (
            <div className="menu">
                <div className="menu-item" key={1}>
                    <div className="menu-item-a">
                        <Link to="/#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }} onClick={this.props.closeCallback}
                        >
                            Головна
                        </Link>
                    </div>
                </div>
                <div className="menu-item" key={2}>
                    <div className="menu-item-a">
                        <Link to="/living#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }} onClick={this.props.closeCallback}
                        >
                            Проживання
                        </Link>
                    </div>
                </div>
                <div className="menu-item" key={4}>
                    <div className="menu-item-a">
                        <Link to="/gallery#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }} onClick={this.props.closeCallback}
                        >
                            Галерея
                        </Link>
                    </div>
                </div>
                <div className="menu-item" key={5}>
                    <div className="menu-item-a">
                        <Link to="/contacts#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }} onClick={this.props.closeCallback}
                        >
                            Контакти
                        </Link>
                    </div>
                </div>
                <div className="menu-item" key={6}>
                    <div className="menu-item-a">
                        <Link to="/service#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }} onClick={this.props.closeCallback}
                        >
                            Додаткові послуги
                        </Link>
                    </div>
                </div>


            </div>
        )
    }
}


export default MenuContent
