
import "./Booking.css"
import Logo from "../image/header/logo.png";

import { FormattedMessage } from "react-intl";

const Booking = () => {
    return (
        <>
            <div className="BookingFlameWrapper" id="jakorPages">
                <div className="LogoForBooking">
                <img src={Logo}></img>
                </div>
                <div className="BookingFrame">

                    <iframe src="Book1.html" scrolling="no"></iframe>


                </div>
            </div>

            <div className="ReqForBook">
                <h1><FormattedMessage id="GlavaBookMain" /></h1>
                <h2><FormattedMessage id="Glava1BookMain" /></h2>
                <p> <FormattedMessage id="Glava1Book" /></p>
                <h2><FormattedMessage id="Glava2BookMain" /></h2>
                <p><FormattedMessage id="Glava2Book" /></p>
                <h2><FormattedMessage id="Glava3BookMain" /></h2>

                <p><FormattedMessage id="Glava3Book" /></p>
                <h2><FormattedMessage id="Glava4BookMain" /></h2>
                <p><FormattedMessage id="Glava4Book" /></p>
            </div>
        </>
    )
}


export default Booking;