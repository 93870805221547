import LivingSlider from "../../Living/LivingSlider/LivingSlider";
import HeadForAllPage from "../../HeadForAllPage/HeadForAllPage";
import cot1 from "../../image/gallery/2.jpg";
import cot2 from "../../image/nomers/cot2.jpg";
import cot3 from "../../image/nomers/cot3.jpg";
import cot4 from "../../image/nomers/cot4.jpg";
import cot5 from "../../image/nomers/cot5.jpg";
import cot6 from "../../image/nomers/cot6.jpg";
import Numbers from "../Numbers.js";
import kam from "../../image/kam.png";
import fen from "../../image/fen.png";
import wifi from "../../image/wifi.png";
import TV from "../../image/TV.png";
import hol from "../../image/hol.png";
import ket from "../../image/kettle.png";
import toelet from "../../image/Toelet.png";
import balcon from "../../image/balcon.png";
import { FormattedMessage } from "react-intl";

const Cottege = () => {
  return (
    <div id="jakorPages">
      <HeadForAllPage></HeadForAllPage>
      <LivingSlider name1={cot2} name2={cot1} name3={cot3} name4={cot4} name5={cot6} name6={cot5} />
      <div className="LuxeBenefitsWrapper">
        <div className="BenefitsWraper">
          <div className="BenefitsWraperIcon">
            <div className="BenefitsTitle1"><FormattedMessage id='Amenities' /></div>
            <div className="BenefitsLine"></div>
            <div className="BenefitsIconWrapper">
              <div className="BenefitsIcon">
                <img className="Tick" src={kam} ></img><FormattedMessage id='Kamin' />
              </div>

              <div className="BenefitsIcon">
                <img className="Tick" src={wifi}></img>
                Wifi
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={fen}></img><FormattedMessage id='Fen' />
              </div>

              <div className="BenefitsIcon">
                <img className="Tick" src={TV}></img><FormattedMessage id='Tele' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={hol}></img><FormattedMessage id='Holod' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={balcon}></img><FormattedMessage id='Balcon' />
              </div>

              <div className="BenefitsIcon">
                <img className="Tick" src={ket}></img><FormattedMessage id='Electro' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={toelet}></img><FormattedMessage id='Toelet' />
              </div>
            </div>
          </div>
          <div className="BookingCardWrapper">
            <div className="BookingTitleCoteg"><FormattedMessage id='Cottege' /></div>
            <div className="BookingLine"></div>
            <div className="BookingPrice"><FormattedMessage id='Price5' /></div>
            <div className="BookingCard">
              <div className="allInBookLiving">


                <iframe src="Book1.html" scrolling="no"></iframe>


              </div>
            </div>
          </div>
        </div>
        <Numbers
          name={<FormattedMessage id='Opis1' />}
        />
      </div>
    </div>
  );
};

export default Cottege;
