import "./Contacts.css";
import HeadForAllPage from "../HeadForAllPage/HeadForAllPage";
import { HomeOutlined, PhoneOutlined, MailOutlined, GlobalOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const Contacts = () => {
    return (
        <div className="allInContacts" id="jakorPages">
            <HeadForAllPage></HeadForAllPage>
            <div className="allInCon2">
                <div className="infoContact2">
                    <div className="MainContText2">
                        <FormattedMessage id='MainContText2' />
                    </div>
                    <div className="TextContaxtWrapper2">
                        <div className="TextContacts2">
                            <div className="UpperFooterContacts2">
                                <HomeOutlined />
                            </div>
                            <div className="TextContacts2">
                                <FormattedMessage id='Place' />
                            </div>
                        </div>

                        <div className="TextContacts2">
                            <div className="UpperFooterContacts2">
                                <PhoneOutlined />
                            </div>
                            <div className="TextContacts2">
                                +3 (098) 111-20-20
                                <br />
                                +3 (098) 024-82-81
                            </div>
                        </div>

                        <div className="TextContacts2">
                            <div className="UpperFooterContacts2">
                                <MailOutlined />
                            </div>
                            <div className="TextContacts2">alpiyskyi@gmail.com</div>
                        </div>

                        <div className="TextContacts2">
                            <div className="UpperFooterContacts2">
                                <GlobalOutlined />
                            </div>
                            <div className="TextContacts2">www.alphotel.com.ua</div>
                        </div>
                    </div>
                </div>
                <div className="allInContact2">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2653.233024251086!2d24.476184415180413!3d48.317600746584965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47371f24edb0cd37%3A0x82fa73f3c91f7343!2z0J7RgtC10LvRjCDQkNC70YzQv9C40LnRgdC60LjQuQ!5e0!3m2!1sru!2sua!4v1626724645059!5m2!1sru!2sua" width="100%" height="509px" style={{ 'borderRadius': '15px', 'border': 'none' }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Contacts;
