import "./App.css";
import MainFooter from "./Footer/MainFooter";
import Contacts from "./Contacts/Contacts";
import Gallery from "./Gallery/Gallery";
import Living from "./Living/Living";
import Main from "./Main/Main";
import Service from "./Service/Service";
import Cottege from "./Numbers/Cottege/Cottege";
import Luxe from "./Numbers/Luxe/Luxe";
import ThreePlacesWithBalcon from "./Numbers/ThreePlacesWithBalcon/ThreePlacesWithBalcon";
import TwoPlacesWithBalcon from "./Numbers/TwoPlacesWithBalcon/TwoPlacesWithBalcon";
import TwoPlacesWithMans from "./Numbers/TwoPlacesWithMans/TwoPlacesWithMans";
import { HashRouter as Router, Route } from "react-router-dom";
import { LOCALES } from "./i18n/locales";
import { I18nPropvider } from "./i18nProvider";
import { useState } from "react";

import { NavLink } from "react-router-dom";
import { IconBase } from "./Header/Header.style";
import "./Header/Header.css";
import { PhoneOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import MainHam from "./Header/MainHam";
import Book from "./Main/Book/Book.js";
import Booking from "./Booking/Booking";


const App = () => {



  const [locale, setLocale] = useState(LOCALES.UKRAINE);

  const Buttons = (props) => {
    return (
      <div className="ButtoWraper">
        <button className="ButtonLanguage" onClick={() => setLocale(LOCALES.UKRAINE)}>UK</button>
        <button className="ButtonLanguage" onClick={() => setLocale(LOCALES.ENGLISH)}>EN</button>
        <button className="ButtonLanguage" onClick={() => setLocale(LOCALES.RUSSIAN)}>RU</button>
      </div>
    )
  };

  const Header = (props) => {


    return (
      <nav className="navHead" >
        <div className="Language_tell">




          <Buttons></Buttons>



          <div className="phoneAndLaung">
            <div className="animatPhone">
              <li className="phone">
                <div>
                  <IconBase component={PhoneOutlined} />
                </div>
              </li>
              <tell className="Nomer">+3 (098) 111-20-20</tell>
            </div>
          </div>
        </div>
        <div className="HeaderMenu">
          <div className="headerLinks">
            <NavLink
              exact
              to="/"
              className="super"
              activeClassName="ActivePage"
              style={{ textDecoration: "none", color: "black" }}
            >
              <FormattedMessage id="Main" />
            </NavLink>
          </div>
          <div className="headerLinks">
            <NavLink
              to="/living"
              className="super"
              activeClassName="ActivePage"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div>
                <FormattedMessage id="Living" />
              </div>
            </NavLink>
          </div>
          <div className="headerLinks">
            <NavLink
              to="/gallery"
              className="super"
              activeClassName="ActivePage"
              style={{ textDecoration: "none", color: "black" }}
            >
              <FormattedMessage id="Gallery" />
            </NavLink>
          </div>
          <div className="headerLinks">
            <NavLink
              to="/contacts"
              className="super"
              activeClassName="ActivePage"
              style={{ textDecoration: "none", color: "black" }}
            >
              <FormattedMessage id="MainContText2" />
            </NavLink>
          </div>
          <div className="headerLinks">
            <NavLink
              to="/service"
              className="super"
              activeClassName="ActivePage"
              style={{ textDecoration: "none", color: "black" }}
            >
              <FormattedMessage id="Service" />
            </NavLink>
          </div>
        </div>
      </nav>
    );
  };



  return (
    <Router>
      <I18nPropvider locale={locale}>
        <div className="burgerOk">
          <MainHam />
        </div>
        <Header />
        <Book></Book>
        <div>
          <Route exact={true} path="/" component={Main} />
          <Route path="/living" component={Living} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/service" component={Service} />
          <Route path="/cottege" component={Cottege} />
          <Route path="/luxe" component={Luxe} />
          <Route path="/booking" component={Booking}></Route>
          <Route
            path="/threePlacesWithBalcon"
            component={ThreePlacesWithBalcon}
          />
          <Route path="/twoPlacesWithBalcon" component={TwoPlacesWithBalcon} />
          <Route path="/twoPlacesWithMans" component={TwoPlacesWithMans} />
        </div>
        <MainFooter />
      </I18nPropvider>
    </Router>
  );
};

export default App;
