import "../Living/LivingSlider/LivingSlider.css";

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow } from "swiper/core";

// install Swiper modules
SwiperCore.use([EffectCoverflow]);



export default function SliderCotage(props) {
  return (
    <div className='swiper-container' style={{ 'marginTop': '50px' }}>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        initialSlide={1}
        className="mySwiper"
      >
        <SwiperSlide style={{ 'width': '550px', 'height': '345px', 'backgroundSize': 'cover', 'backgroundPosition': 'center' }} >

          <img src={props.name1} alt="" style={{ 'display': 'block', 'width': '100%' }} />
        </SwiperSlide>
        <SwiperSlide style={{ 'width': '550px', 'height': '345px', 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
          <img src={props.name2} alt="" style={{ 'display': 'block', 'width': '100%' }} />
        </SwiperSlide>
        <SwiperSlide style={{ 'width': '550px', 'height': '345px', 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
          <img src={props.name3} alt="" style={{ 'display': 'block', 'width': '100%' }} />
        </SwiperSlide>
        <SwiperSlide style={{ 'width': '550px', 'height': '345px', 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
          <img src={props.name4} alt="" style={{ 'display': 'block', 'width': '100%' }} />
        </SwiperSlide>
        <SwiperSlide style={{ 'width': '550px', 'height': '345px', 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
          <img src={props.name5} alt="" style={{ 'display': 'block', 'width': '100%' }} />
        </SwiperSlide>
        <SwiperSlide style={{ 'width': '550px', 'height': '345px', 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
          <img src={props.name6} alt="" style={{ 'display': 'block', 'width': '100%' }} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
