import React, {useEffect,useState} from 'react';
import Card from './Card';

const weatherURL = "https://api.openweathermap.org/data/2.5/forecast?q=Vorokhta&APPID=b22ddf6b33070227058c83495ebe760c";


const WeatherMain = () => {
  const [days,setDays] = useState([]);

  useEffect(()=>{
  fetch(weatherURL)
.then(res => res.json())
.then(data => {
  const dailyData = data.list

  setDays(dailyData)
})
},[])


    return (
      <div className="et">
        <div className="row justify-content-center">

          {days.slice(0,1).map((day) => <Card day={day}/>)}

        </div>
      </div>
    )
}

export default WeatherMain
