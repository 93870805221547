
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import P1 from '../../image/rating/P1.png'
import P2 from '../../image/rating/P2.png'
import P3 from '../../image/rating/P3.png'
import P4 from '../../image/rating/P4.png'
import P5 from '../../image/rating/P5.png'
import P6 from '../../image/rating/P6.png'
import P7 from '../../image/rating/P7.png'
import P8 from '../../image/rating/P8.png'
import P9 from '../../image/rating/P9.png'
import P10 from '../../image/rating/P10.png'
import { FormattedMessage } from "react-intl";

import "swiper/swiper-bundle.min.css";
import "./Reviews.css";

import SwiperCore, {
  Pagination, Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);


export default function Reviews() {



  return (
    <div className="MainSwiperReview">
      <div className="ReviewTitle"><FormattedMessage id="Review"></FormattedMessage></div>

      <Swiper pagination={{
        "type": "progressbar"
      }} navigation={true} className="mySwiper" >

        <SwiperSlide><div className="SlideReview">
          <img src={P1} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Турфірма Окрайна</p><div className="SlideText">Від готелю "Альпійський" відкриваються види на гори - Говерлу, Петрос, Близнюки, а такими краєвидами не кожен готель по доступних цінах, доступний :) На мікробусі ми піднялися 700м вгору, де й розташувався затишний в австрійському стилі готельчик. Номери чистенькі, біленькі, душові працють справно. Через балкони видніються смереки і зранку прибігають чорні карпатські білочки, ми ще й жартували після вжитих градусів, що до нас білочка має прийти :) Було затишно, а що для щастя потрібно - спокій душі і гарні краєвиди.</div>
        </div></SwiperSlide>

        <SwiperSlide><div className="SlideReview">
          <img src={P2} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Дмитро Лісковецький (Liso4ok)</p><div className="SlideText">Всім рекомендую! Привітний персонал, у номерах чисто, є де припаркувати автомобіль, красивий вид з вікон. Адекватні ціни)</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P3} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva" >Istvan Lukach</p><div className="SlideText">Затишний готель, з гарними видами на гори. Номер теплий, відносно невеличкого розміру, санвузол оснащений та справний. Дві парковки, одна на території інша через дорогу. Рекомендую :)</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P4} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Елена Солнечная</p><div className="SlideText">Прекрасне місце! Ідеальне співвідношення ціни та якості. Привітний персонал, затишні номери, місце для барбекю з прекрасним виглядом на Говерлу, альтанки, настрільний теніс, дитячий майданчик. Одним словом все для чудового відпочинку)</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P5} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Vitalii Europe</p><div className="SlideText">Чудовий готель для комфортного відпочинку. Гарний коридор з спіральними гранітними східцями, панорамна велика тераса з виглядом на гори в коридорі, затишний балкон у номері. Чисто, затишно, гарні меблі та свіжі, не затерті стіни. Приємний персонал. Дерев'яні бєсєдки на вулиці.</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P6} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Коп Україна</p><div className="SlideText">Зараз відпочиваємо в "Альпійському", враження найкращі! Чистота, сервіс, неймовірні пейзажі. Привітний персонал, який цінує та поважає кожного відвідувача. Поблизу назбирали білих!!! Рекомендую!!!!!!!!!</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P7} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Viktoria Krukovets</p><div className="SlideText">Замечательный отель, номера чистые, уютные, всё работает исправно. Приветливый отзывчивый персонал, всё помогут, всё подскажут (отдельная благодарность администратору Нели), вкусная домашняя кухня. Прекрасное расположение, особенно покорил панорамный вид с террас. Рекомендую!</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P8} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Diana Berestovaya</p><div className="SlideText">Были в этом отеле уже 2 раза . Приезжали большой компанией , летом и вот зимой на Новый год . Сам коттедж комфортный , уютный , все чистенько , много места , очень тепло . ))Порадовали нас шампанским и вкусными конфетками на Новый год ) Хорошее месторасположение , вкусная еда , прекрасное настроение ) Будем возвращаться сюда неоднократно ))</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P9} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Юлія Лебедєва</p><div className="SlideText">Афигенно, нам очень сильно понравилось. Обслуживание, вид с номера, с лаунж зоны. Свои альтанки с мангала, но вид просто обескураживает. Мы рекомендуем ❤❤❤</div>
        </div></SwiperSlide>
        <SwiperSlide><div className="SlideReview">
          <img src={P10} style={{ height: '40px', width: '40px' }}></img><p className="SlidTitleAva">Adrian Zima</p><div className="SlideText">Я забронював тут на 10 днів, і мені було дуже комфортно, персонал дуже ввічливий і привітливий, номер має всі зручності і дуже гостинна. Настійно рекомендується</div>
        </div></SwiperSlide>
      </Swiper>
    </div>
  )
}