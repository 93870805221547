import "./Footer.css";
import { FormattedMessage } from "react-intl";
import { HashLink as Link } from 'react-router-hash-link';

const Footer = () => {
    return (
        <nav className="nav">
            <div className="footerMenu">
            <div className="text">
                Alphotel 2021
            </div>
                <div className="footerSimpleLinks">
                    <Link  to="/#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}>
                    <FormattedMessage id='Main' />
                    </Link>
                </div>
                <div className="footerSimpleLinks">
                    <Link to="/living#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}>
                    <FormattedMessage id='Living' />
                    </Link>
                </div>
                <div className="footerSimpleLinks">
                    <Link to="/gallery#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}>
                    <FormattedMessage id='Gallery' />
                    </Link>
                </div>
                <div className="footerSimpleLinks">
                    <Link to="/contacts#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}>
                    <FormattedMessage id='MainContText2' />
                    </Link>
                </div>
                <div className="footerSimpleLinks">
                    <Link to="/service#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }} >
                    <FormattedMessage id='Service' />
                    </Link>
                </div>
            </div>
        </nav>
    )
}

export default Footer;
