import Footer from "./Footer";
import UpperFooter from "./UpperFooter";

const MainFooter = () => {
   return(<div className="main"> 
     <UpperFooter />
     <Footer />
     </div>
   )
}

export default MainFooter;