
import "./Benefits.css"; 
import Sauna from "../../image/Banka.png" 
import Tennis from "../../image/tennis.png" 
import Gazebo from "../../image/Gazebo.png" 
import Cafeshka from "../../image/Cafeshka.png" 
import Wifi2 from "../../image/wifi2.png" 
import playground from "../../image/playground.png" 
import parking from "../../image/parking.png" 
import SkiLift from "../../image/SkiLift.png" 
import handShake from "../../image/handShake.png" 
import { FormattedMessage } from "react-intl"; 
import { HashLink as Link } from 'react-router-hash-link';
 
const Benefits = () => { 
  return ( 
    <div className="BenefistWrapper"> 
      <div className="firstJard"> 
        <div className="SimpleJard" ><Link to="/service#Sauna" style={{ textDecoration: "none", color: "black" }}><img src={Sauna} /><h1><FormattedMessage id="BenefitsMain5"></FormattedMessage></h1></Link></div> 
        <div className="SimpleJard" ><div style={{ textDecoration: "none", color: "black", paddingTop: " 15px" }}><img src={parking} /><h1><FormattedMessage id="BenefitsMain3"></FormattedMessage></h1></div></div> 
        <div className="SimpleJard" ><Link to="/service#Gazebo" style={{ textDecoration: "none", color: "black" }}><img src={Gazebo} /><h1><FormattedMessage id="BenefitsMain8" /></h1></Link></div> 
      </div> 
      <div className="firstJard"> 
        <div className="SimpleJard" ><Link to="/service#Cafeshka" style={{ textDecoration: "none", color: "black" }}><img src={Cafeshka} /><h1><FormattedMessage id="CafeM" /></h1></Link></div> 
        <div className="SimpleJard" ><div style={{ textDecoration: "none", color: "black" }}><img src={Wifi2} /><h1>Wifi</h1></div></div> 
        <div className="SimpleJard" ><Link to="/service#playground" style={{ textDecoration: "none", color: "black" }}><img src={playground} /><h1><FormattedMessage id="BenefitsMain1" /></h1></Link></div> 
      </div> 
      <div className="firstJard"> 
        <div className="SimpleJard" ><Link to="/service#Tennis" style={{ textDecoration: "none", color: "black" }}><img src={Tennis} /><h1><FormattedMessage id="TennisM" /></h1></Link></div> 
        <div className="SimpleJard" ><div style={{ textDecoration: "none", color: "black" }}><img src={SkiLift} /><h1><FormattedMessage id="BenefitsMain6" /></h1></div></div> 
        <div className="SimpleJard" ><Link to="/service#handShake" style={{ textDecoration: "none", color: "black" }}><img src={handShake} /><h1><FormattedMessage id="BenefitsMain10" /></h1></Link></div> 
      </div> 
    </div> 
  ); 
}; 
 
export default Benefits;
