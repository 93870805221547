import "./Luxe.css";
import Numbers from "../Numbers.js";
import LivingSlider from "../../Living/LivingSlider/LivingSlider";
import J37 from "../../image/gallery/37.jpg";
import J36 from "../../image/gallery/36.jpg";
import J39 from "../../image/gallery/39.jpg";
import J38 from "../../image/gallery/38.jpg";
import luxe3 from "../../image/nomers/luxe3.jpg";
import luxe4 from "../../image/nomers/luxe4.jpg";
import HeadForAllPage from "../../HeadForAllPage/HeadForAllPage";
import { FormattedMessage } from "react-intl";
import fen from "../../image/fen.png";
import wifi from "../../image/wifi.png";
import TV from "../../image/TV.png";
import hol from "../../image/hol.png";
import ket from "../../image/kettle.png";
import mikro from "../../image/mikro.png";
import toelet from "../../image/Toelet.png";

const Luxe = () => {
  return (
    <div id="jakorPages">
      <HeadForAllPage ></HeadForAllPage>
      <LivingSlider name1={J37} name2={J36} name3={J39} name4={J38} name5={luxe3} name6={luxe4} />
      <div className="LuxeBenefitsWrapper">
        <div className="BenefitsWraper">
          <div className="BenefitsWraperIcon">
            <div className="BenefitsTitle1"><FormattedMessage id='Amenities' /></div>
            <div className="BenefitsLine"></div>
            
            <div className="BenefitsIconWrapper">
              <div className="BenefitsIcon">
                <img className="Tick" src={wifi}></img>
                Wifi
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={fen}></img><FormattedMessage id='Fen' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={TV}></img><FormattedMessage id='Tele' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={hol}></img><FormattedMessage id='Holod' />
              </div>
              
              <div className="BenefitsIcon">
                <img className="Tick" src={mikro}></img><FormattedMessage id='Pich' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={ket}></img><FormattedMessage id='Electro' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={toelet}></img><FormattedMessage id='Toelet' />
              </div>
            </div>
          </div>
          <div className="BookingCardWrapper">
            <div className="BookingTitle"><FormattedMessage id='Luxe' /></div>
            <div className="BookingLine"></div>
            <div className="BookingPrice"><FormattedMessage id='Price1' /></div>
            
            <div className="BookingCard">
              <div className="allInBookLiving">

                  
                  <iframe src="Book1.html" scrolling="no"></iframe>
                

              </div>
            </div>

          </div>
        </div>
        <Numbers
          name={<FormattedMessage id='Opis2' />}
        />
      </div>
    </div>
  );
};

export default Luxe;
