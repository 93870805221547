import "./AboutUs.css";
import { FormattedMessage } from "react-intl";
import AboutUsImg from "../../image/gallery/1.jpg";

const AboutUs = () => {
  return (
    <div className=" containerrr">
      <div className="AboutUsWrapper">
        <div className="AboutUsTextWrapper">
          <div className="AboutUsTitle">
            <FormattedMessage id="AboutUsMainTitle" />
          </div  >
          <div className="textAboutUs"><FormattedMessage id="AboutUsMain" /></div>

        </div>

        <img className="AboutUsImg" src={AboutUsImg}></img>

      </div>
    </div>
  );
};

export default AboutUs;
