import "./Service.css";
import HeadForAllPage from "../HeadForAllPage/HeadForAllPage";
import J44 from "../image/gallery/44.jpg";
import J6 from "../image/gallery/6.jpg";
import Seven from "../image/gallery/blin.jpg";
import J43 from "../image/gallery/43.jpg";
import Main228 from "../image/nomers/Main228.jpg";
import { FormattedMessage } from "react-intl";
import sky from "../image/sky.png";
import bike from "../image/bike.png";
import fishing from "../image/fishing.png";
import jeep from "../image/jeep.png";
import sauna from "../image/sauna.png";
import raf from "../image/raf.png";
import car from "../image/car.png";
import playgr from "../image/gallery/10.jpg";

const Service = () => {
  return (
    <div id="jakorPages">
      <HeadForAllPage></HeadForAllPage>
      <div className="allContact">
        <div className="firstPar" id="Sauna"  >
          <p><FormattedMessage id='SaunaM' /></p>
        </div>
        <div className="ServiseWrapper">
          <div className="TextInService">
            <div className="textServ">

              <FormattedMessage id='Sauna1' />

              <div className="Sauna2"><FormattedMessage id='Sauna2' /></div>
            </div>
          </div>
          <img className="imageService" src={J44} alt="bana"></img>
        </div>
        <div className="secondPar" id="Tennis">
          <p><FormattedMessage id='TennisM' /></p>
        </div>
        <div className="ServiseWrapper">

          <img className="imageService" src={J6} alt="bana"></img>
          <div className="TextInService">
            <div className="textServ">

              <FormattedMessage id='Tennis' />
            </div>
          </div>
        </div>
        <div className="firstPar" id="Gazebo">
          <p><FormattedMessage id='AltanM' /></p>
        </div>
        <div className="ServiseWrapper">
          <div className="TextInService">
            <div className="textServ">

              <FormattedMessage id='Altan' />
            </div>
          </div>
          <img className="imageService" src={Seven} alt="bana"></img>
        </div>
        <div className="secondPar" id="Cafeshka">
          <p><FormattedMessage id='CafeM' /></p>
        </div>
        <div className="ServiseWrapper">

          <img className="imageService" src={J43} alt="bana"></img>
          <div className="TextInService">
            <div className="textServ">

              <FormattedMessage id='Cafe2' />
            </div>
          </div>
        </div>
        <div className="firstPar" id="playground">
          <p><FormattedMessage id="BenefitsMain1" /></p>
        </div>
        <div className="ServiseWrapper">
          <div className="TextInService">
            <div className="textServ">

              <FormattedMessage id='BenefitsMain12' /><br />
              <FormattedMessage id='BenefitsMain22' />
            </div>
          </div>
          <img className="imageService" src={playgr} alt="bana"></img>
        </div>

        <div className="secondPar LongTextFirstPar" id="Tufta" id="handShake">
          <p><FormattedMessage id='Other228' /></p>
        </div>
        <div className="ServiseWrapper" >
          <img className="imageService" src={Main228} alt="bana"></img>
          <div className="TextInService" >
            <div className="textServ">

              <img src={sky} style={{ paddingRight: '10px', width: '42px' }} /><FormattedMessage id='Other227' /><br /><br />
              <img src={bike} style={{ paddingRight: '10px', width: '42px' }} /><FormattedMessage id='Other226' /><br /><br />
              <img src={jeep} style={{ paddingRight: '10px', width: '42px' }} /><FormattedMessage id='Other225' /><br /><br />
              <img src={sauna} style={{ paddingRight: '10px', width: '42px' }} /><FormattedMessage id='Other224' /><br /><br />
              <img src={fishing} style={{ paddingRight: '10px', width: '42px' }} /><FormattedMessage id='Other223' /><br /><br />
              <img src={raf} style={{ paddingRight: '10px', width: '42px' }} /><FormattedMessage id='Other222' /><br /><br />

              <img src={car} style={{ paddingRight: '10px', width: '42px' }} /><FormattedMessage id='Other221' /><br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
