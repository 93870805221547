import "./HeadForAllPage.css";
import Logo from "../image/header/logo.png";
import ForAll from "../image/end/ForAll.jpg";

const HeadForAllPage = () => {
  return (
    <div className="BackgroundWrapper">
      <div className="Imgas2">
        <div className="ImgBackground2">
          <div className="LogoDiv2">
            <img className="Logo2" src={Logo}></img>
          </div>

          <img className="Background2" src={ForAll}></img>
        </div>
      </div>
    </div>
  );
};

export default HeadForAllPage;
  