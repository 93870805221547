import LivingSlider from "../../Living/LivingSlider/LivingSlider";
import HeadForAllPage from "../../HeadForAllPage/HeadForAllPage";
import J48 from "../../image/gallery/48.jpg";
import twoplace2 from "../../image/nomers/twoplace2.jpg";
import two3 from "../../image/nomers/two3.jpg";
import two4 from "../../image/nomers/two4.jpg";
import two5 from "../../image/nomers/two5.jpg";
import two6 from "../../image/nomers/two6.jpg";
import Numbers from "../Numbers.js";
import fen from "../../image/fen.png";
import wifi from "../../image/wifi.png";
import TV from "../../image/TV.png";
import hol from "../../image/hol.png";
import balcon from "../../image/balcon.png";
import toelet from "../../image/Toelet.png";

import { FormattedMessage } from "react-intl";

const TwoPlacesWithBalcon = () => {
  return (
    <div id="jakorPages">
      <HeadForAllPage></HeadForAllPage>
      <LivingSlider name1={J48} name2={twoplace2} name3={two5} name4={two6} name5={two3} name6={two4} />
      <div className="LuxeBenefitsWrapper">
        <div className="BenefitsWraper">
          <div className="BenefitsWraperIcon">
            <div className="BenefitsTitle1"><FormattedMessage id='Amenities' /></div>
            <div className="BenefitsLine"></div>
            <div className="BenefitsIconWrapper">
              <div className="BenefitsIcon">
                <img className="Tick" src={wifi}></img>
                Wifi
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={fen}></img><FormattedMessage id='Fen' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={TV}></img><FormattedMessage id='Tele' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={hol}></img><FormattedMessage id='Holod' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={balcon}></img><FormattedMessage id='Balcon' />
              </div>
              <div className="BenefitsIcon">
                <img className="Tick" src={toelet}></img><FormattedMessage id='Toelet' />
              </div>
            </div>
          </div>
          <div className="BookingCardWrapper">
            <div className="BookingTitle3WithBalcon">
            <FormattedMessage id='TwoPlace' />
            </div>
            <div className="BookingLine"></div>
            <div className="BookingPrice"><FormattedMessage id='Price4' /></div>
            <div className="BookingCard">
              <div className="allInBookLiving">

                  
                  <iframe src="Book1.html" scrolling="no"></iframe>
                

              </div>
            </div>
          </div>
        </div>
      </div>
      <Numbers name={<FormattedMessage id='Opis5' />}/>
    </div>
  );
};

export default TwoPlacesWithBalcon;
