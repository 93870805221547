import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import J34 from "../../image/gallery/34.jpg";
import J40 from "../../image/gallery/40.jpg";
import J48 from "../../image/gallery/48.jpg";
import Twelve from "../../image/gallery/12.jpg";
import { FormattedMessage } from "react-intl";
import two6 from "../../image/nomers/two6.jpg";
// Import Swiper styles
import "swiper/swiper.min.css";
import { HashLink as Link } from 'react-router-hash-link';
import "./SliderMain.css";


// import Swiper core and required modules
import SwiperCore, {
} from 'swiper/core';
import { NavLink } from "react-router-dom";

// install Swiper modules


export default function SliderMain() {



  return (<div className="AllInSliderMain">
    <h1 className="CottegesAndBlabLa"><FormattedMessage id='CottegesAndNum' /></h1>
    <>
      <div className="SliderForBig">
        <div className="SliderForSmall123">
        <Swiper slidesPerView={3} spaceBetween={45} style={{ height: "350px" }}>
          <SwiperSlide><Link to="/luxe#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J34}>
          </img><div className="WordsInSliderMain">
              <FormattedMessage id='Luxe' /></div>
            <div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' />
            </div></div></Link>

          </SwiperSlide>

          <SwiperSlide><Link to="/twoPlacesWithMans#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J40}></img><div className="WordsInSliderMain"><FormattedMessage id='WithMan' /></div><div to="/twoPlacesWithMans" style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div></Link></SwiperSlide>
          <SwiperSlide><Link to="/threePlacesWithBalcon#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={two6}></img><div className="WordsInSliderMain"><FormattedMessage id='ThreePlace' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div></Link></SwiperSlide>
          <SwiperSlide><Link to="/twoPlacesWithBalcon#jakorPages" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J48}></img><div className="WordsInSliderMain"><FormattedMessage id='TwoPlace' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div> </Link></SwiperSlide>
           </Swiper>
           <div className="MargineSlider">
           <Swiper>
           <SwiperSlide><NavLink to="/cottege" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={Twelve}></img><div className="WordsInSliderMain"><FormattedMessage id='Cottege' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div ></NavLink></SwiperSlide>
           </Swiper>
           </div>
           </div>

      </div>
      <div className="SliderForSmall">
        <Swiper slidesPerView={2} spaceBetween={15} pagination={{
          "clickable": true
        }} className="mySwiper">
          <SwiperSlide>
            <NavLink to="/luxe" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J34}>
          </img><div className="WordsInSliderMain">
              <FormattedMessage id='Luxe' /></div>
            <div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' />
            </div></div>
            </NavLink>

          </SwiperSlide>

          <SwiperSlide><NavLink to="/twoPlacesWithMans" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J40}></img><div className="WordsInSliderMain"><FormattedMessage id='WithMan' /></div><div to="/twoPlacesWithMans" style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div></NavLink></SwiperSlide>
          <SwiperSlide><NavLink to="/threePlacesWithBalcon" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={two6}></img><div className="WordsInSliderMain"><FormattedMessage id='ThreePlace' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div></NavLink></SwiperSlide>
          <SwiperSlide><NavLink to="/twoPlacesWithBalcon" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J48}></img><div className="WordsInSliderMain"><FormattedMessage id='TwoPlace' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div> </NavLink></SwiperSlide>
           </Swiper>
           <Swiper>
           <SwiperSlide><NavLink to="/cottege" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={Twelve}></img><div className="WordsInSliderMain"><FormattedMessage id='Cottege' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div ></NavLink></SwiperSlide>
           </Swiper>
      </div>
      <div className="SliderForSmallest">
        <Swiper slidesPerView={1} spaceBetween={10} pagination={{
          "clickable": true
        }} className="mySwiper">
          <SwiperSlide><NavLink to="/luxe" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J34}>
          </img><div className="WordsInSliderMain">
              <FormattedMessage id='Luxe' /></div>
            <div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' />
            </div></div></NavLink>

          </SwiperSlide>

          <SwiperSlide><NavLink to="/twoPlacesWithMans" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J40}></img><div className="WordsInSliderMain"><FormattedMessage id='WithMan' /></div><div to="/twoPlacesWithMans" style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div></NavLink></SwiperSlide>
          <SwiperSlide><NavLink to="/threePlacesWithBalcon" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={two6}></img><div className="WordsInSliderMain"><FormattedMessage id='ThreePlace' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div></NavLink></SwiperSlide>
          <SwiperSlide><NavLink to="/twoPlacesWithBalcon" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={J48}></img><div className="WordsInSliderMain"><FormattedMessage id='TwoPlace' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div> </NavLink></SwiperSlide>
          
          </Swiper>
        <Swiper>
           <SwiperSlide><NavLink to="/cottege" style={{ 'textDecoration': 'none', 'color': 'black' }}><div className='CardSlider'><img src={Twelve}></img><div className="WordsInSliderMain"><FormattedMessage id='Cottege' /></div><div style={{ 'textDecoration': 'none', 'color': 'black', 'opacity': '60%', 'fontSize': '13px', 'fontStyle': 'italic' }}><FormattedMessage id='Details' /></div></div ></NavLink></SwiperSlide>
           </Swiper>
      </div>
    </>
  </div>
  )
}