import React from "react";
import "./RecomendFor.css"
import Book2016 from "../../image/rating/2016Book.jpg"
import Book2018 from "../../image/rating/2018Book.png"
import Book2020 from "../../image/rating/2020Book.png"
import Book2021 from "../../image/rating/2021Book.png"
import { FormattedMessage } from "react-intl";

export default function RecomendFor() {

  return (
    <div className="RecomendWrapper">
      <div className="ReviewTitle"><FormattedMessage id="Stars"></FormattedMessage> Booking.com</div>
      <img src={Book2021}></img>
      <img src={Book2020}></img>
      <img src={Book2018}></img>
      <img src={Book2016}></img>
    </div>
  )
}