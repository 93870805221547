import "./Gallery.css";
import One from "../image/gallery/1.jpg";
import Two from "../image/gallery/2.jpg";
import Three from "../image/gallery/3.jpg";
import Four from "../image/gallery/4.jpg";
import Five from "../image/gallery/5.jpg";
import Six from "../image/gallery/6.jpg";
import Seven from "../image/gallery/7.jpg";
import Eight from "../image/gallery/8.jpg";
import Nine from "../image/gallery/9.jpg";
import Ten from "../image/gallery/10.jpg";
import Eleven from "../image/gallery/11.jpg";
import Fiveteen from "../image/gallery/15.jpg";
import Sixteen from "../image/gallery/16.jpg";
import J17 from "../image/gallery/17.jpg";
import J18 from "../image/gallery/18.jpg";
import J19 from "../image/gallery/19.jpg";
import J20 from "../image/gallery/20.jpg";
import J21 from "../image/gallery/21.jpg";
import J22 from "../image/gallery/22.jpg";
import J23 from "../image/gallery/23.jpg";
import J24 from "../image/gallery/24.jpg";
import J25 from "../image/gallery/25.jpg";
import J26 from "../image/gallery/26.jpg";
import J27 from "../image/gallery/27.jpg";
import J28 from "../image/gallery/28.jpg";
import J29 from "../image/gallery/29.jpg";
import J30 from "../image/gallery/30.jpg";
import J31 from "../image/gallery/31.jpg";
import J32 from "../image/gallery/32.jpg";
import J33 from "../image/gallery/33.jpg";
import J34 from "../image/gallery/34.jpg";
import J35 from "../image/gallery/35.jpg";
import J36 from "../image/gallery/36.jpg";
import J38 from "../image/gallery/38.jpg";
import J39 from "../image/gallery/39.jpg";
import J40 from "../image/gallery/40.jpg";
import J41 from "../image/gallery/41.jpg";
import J42 from "../image/gallery/42.jpg";
import J43 from "../image/gallery/43.jpg";
import J44 from "../image/gallery/44.jpg";
import J45 from "../image/gallery/45.jpg";
import J46 from "../image/gallery/46.jpg";
import J47 from "../image/gallery/47.jpg";
import J49 from "../image/gallery/49.jpg";
import J50 from "../image/gallery/50.jpg";
import J51 from "../image/gallery/51.jpg";
import J52 from "../image/gallery/52.jpg";
import J53 from "../image/gallery/53.jpg";
import J54 from "../image/gallery/54.jpg";
import J55 from "../image/gallery/55.jpg";
import J56 from "../image/gallery/56.jpg";
import J57 from "../image/gallery/57.jpg";
import J58 from "../image/gallery/58.jpg";
import J59 from "../image/gallery/59.jpg";
import J60 from "../image/gallery/60.jpg";
import React from 'react';
import HeadForAllPage from "../HeadForAllPage/HeadForAllPage";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const images = [
    J60,J59,J58,J57,J56,J55, One, Two, Three, Four, Five, Six, Seven, Eight, Nine, Ten, Eleven,  Fiveteen, Sixteen, J17, J18, J19, J20, J21, J22, J23,
    J24, J25, J26, J27, J28, J29, J30, J31, J32, J33, J34, J35, J36, J38,
    J39, J40, J41, J42, J43, J44, J45, J46, J47, J49, J50, J51, J52, J53, J54
];

const Gallery = React.memo(() => {
    return (
        <div id="jakorPages">
            <HeadForAllPage />
            <SimpleReactLightbox>
                <SRLWrapper>
                    <div className="Galler">
                        {images.map((image, index) => (
                            <img key={index} src={image} loading="lazy" alt={`Gallery ${index + 1}`} />
                        ))}
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
            <div className="Caps"></div>
        </div>
    );
});

export default Gallery;

